import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxChevronLeft, RxPlus } from 'react-icons/rx'

import { Checkbox } from '@/components/admin/Checkbox.tsx'
import { StyledTable, TableCell, TableRow } from '@/components/admin/Table.tsx'
import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Switch } from '@/components/common/Switch.tsx'
import { BodyS, Header3 } from '@/components/common/Text.tsx'
import {
  useFeatureFlags,
  useUpdateFeatureFlag,
} from '@/hooks/admin/useFeatureFlags.ts'
import { ActionsMenu } from '@/screens/admin/UserManagement/featureFlags/ActionsMenu.tsx'
import { UploadFileModal } from '@/screens/admin/UserManagement/featureFlags/UploadFileModal.tsx'
import { FeatureFlag } from '@/types/featureFlag.ts'

import * as S from './ExpandFeatureFlag.styled.ts'
import { FlexContainerEnd } from './ExpandFeatureFlag.styled.ts'

type ExpandFeatureFlagProps = {
  featureFlagKey: FeatureFlag['key']
  goBack: () => void
}

export const ExpandFeatureFlag = ({
  featureFlagKey,
  goBack,
}: ExpandFeatureFlagProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.featureFlags.expandFeatureFlag',
  })
  const { featureFlags } = useFeatureFlags()
  const featureFlag = featureFlags.find((flag) => flag.key === featureFlagKey)

  const initialValues = featureFlag?.value || []
  const [filteredValues, setFilteredValues] = useState<string[]>(initialValues)
  const [filterQuery, setFilterQuery] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const [isAddingValue, setIsAddingValue] = useState<boolean>(false)

  const { isError: isErrorUpdating, updateFeatureFlag } = useUpdateFeatureFlag({
    onSuccess: () => {
      setIsAddingValue(false)
      setSelectedValues([])
      setFilterQuery('')
      setFilteredValues(initialValues)
    },
  })

  const handleDeleteSelectedValues = useCallback(() => {
    const newValues = initialValues!.filter(
      (value) => !selectedValues.includes(value),
    )
    updateFeatureFlag({
      featureFlag: {
        ...featureFlag!,
        value: newValues,
      },
      key: featureFlag!.key,
    })
  }, [
    featureFlags,
    featureFlag,
    initialValues,
    selectedValues,
    updateFeatureFlag,
  ])

  const handleFilterFeatureValues = useCallback(
    (query: string | number) => {
      setFilterQuery(query.toString())
      setFilteredValues(
        initialValues!.filter((value) =>
          value.toLowerCase().includes(query.toString().toLowerCase()),
        ),
      )
    },
    [initialValues, setFilterQuery, setFilteredValues],
  )

  if (!featureFlag) {
    return (
      <BodyS>{t('error.noFeatureFlagFound', { key: featureFlagKey })}</BodyS>
    )
  }

  return (
    <>
      <S.Breadcrumb onClick={goBack}>
        <RxChevronLeft /> {t('breadcrumb')}
      </S.Breadcrumb>
      <S.Wrapper>
        <S.Header>
          <Header3>{featureFlag.key}</Header3>
          <Switch
            checked={featureFlag.enabled}
            onChange={(checked) => {
              updateFeatureFlag({
                featureFlag: {
                  ...featureFlag,
                  enabled: checked,
                },
                key: featureFlag.key,
              })
            }}
          />
        </S.Header>
        <S.Title>{featureFlag.title}</S.Title>
        <S.Description>{featureFlag.description}</S.Description>
        <>
          <S.ValuesBlockBar>
            <S.FlexContainer>
              <S.ValuesBlockLabel color="black">
                {t('values')}
              </S.ValuesBlockLabel>
              <S.AddValueButton
                onClick={() => setIsAddingValue((prevState) => !prevState)}
              >
                <RxPlus />
              </S.AddValueButton>
            </S.FlexContainer>
            <S.FlexContainer>
              <S.FilterInput
                placeholder={t('searchbarPlaceholder')}
                value={filterQuery}
                onChange={handleFilterFeatureValues}
              />
              <ActionsMenu
                actions={[
                  {
                    label: t('actions.uploadCsv'),
                    onClick: () => setIsModalOpen(true),
                  },
                ]}
              />
            </S.FlexContainer>
          </S.ValuesBlockBar>
          {isErrorUpdating && (
            <BodyS color="red">{t('error.errorUpdatingFeatureFlag')}</BodyS>
          )}

          {isAddingValue && (
            <S.NewItemInput
              type="text"
              placeholder={t('addNewValuePlaceholder')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  updateFeatureFlag({
                    featureFlag: {
                      ...featureFlag,
                      value: [...initialValues, e.currentTarget.value],
                    },
                    key: featureFlag.key,
                  })
                }
              }}
              onPaste={(e) => {
                const pastedText = e.clipboardData.getData('text') ?? ''
                const rows = pastedText
                  .trim()
                  .split(/\r\n|\r|\n/)
                  .map((row) => row.trim())
                  .filter(Boolean)
                if (rows.length > 1) {
                  e.preventDefault()
                  updateFeatureFlag({
                    featureFlag: {
                      ...featureFlag,
                      value: [...initialValues, ...rows],
                    },
                    key: featureFlag.key,
                  })
                }
              }}
            />
          )}
          {filteredValues?.length === 0 && (
            <BodyS>{t('error.noItemsFound', { query: filterQuery })}</BodyS>
          )}
          {filteredValues && filteredValues.length > 0 && (
            <S.ValuesBlock>
              <StyledTable>
                {filteredValues.map((value) => (
                  <TableRow key={value}>
                    <TableCell>
                      <BodyS>{value}</BodyS>
                    </TableCell>
                    <TableCell>
                      <FlexContainerEnd>
                        <Checkbox
                          checked={selectedValues.includes(value)}
                          onChange={() => {
                            if (selectedValues.includes(value)) {
                              setSelectedValues(
                                selectedValues.filter((val) => val !== value),
                              )
                            } else {
                              setSelectedValues([...selectedValues, value])
                            }
                          }}
                        />
                      </FlexContainerEnd>
                    </TableCell>
                  </TableRow>
                ))}
              </StyledTable>
            </S.ValuesBlock>
          )}
          {selectedValues.length > 0 && (
            <S.ChangeValuesButtons>
              <StyledActionButton
                variant="Secondary"
                onClick={() => setSelectedValues([])}
              >
                {t('actions.clearSelection')}
              </StyledActionButton>
              <StyledActionButton
                variant="Secondary"
                onClick={() => setSelectedValues(filteredValues!)}
              >
                {t('actions.selectAll')}
              </StyledActionButton>
              <StyledActionButton
                variant="Primary"
                onClick={handleDeleteSelectedValues}
              >
                {t('actions.deleteSelected')}
              </StyledActionButton>
            </S.ChangeValuesButtons>
          )}
          {isModalOpen && (
            <UploadFileModal
              closeModal={() => setIsModalOpen(false)}
              onSave={(values) => {
                updateFeatureFlag({
                  featureFlag: {
                    ...featureFlag,
                    value: [...initialValues, ...values],
                  },
                  key: featureFlag.key,
                })
              }}
            />
          )}
        </>
      </S.Wrapper>
    </>
  )
}
